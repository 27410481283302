import React from "react"
import PropTypes from "prop-types"

/* import { Link } from "gatsby" */

import Carousel /* , { Dots } */ from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import ConvertLink from "./ConvertLink"

import ModalObject from "../../components/contents/modalObject"
import Image from "react-bootstrap/Image"

/* 
const ConvertLink2 = (path, child, index) => {
  if (path.includes("http")) {
    return (
      <a key={index} href={path}>
        {child}
      </a>
    )
  } else {
    return (
      <Link key={index} to={`/${path}`}>
        {child}
      </Link>
    )
  }
}
*/

const BannerCarousel = ({ images, paths, isModal }) => {
  return (
    <>
      <Carousel
        infinite
        slidesPerScroll={1}
        slidesPerPage={3}
        centered
        dots
        autoPlay={3000000}
        animationSpeed={300}
        breakpoints={{
          900: {
            slidesPerScroll: 1,
            slidesPerPage: 1,
            arrows: true,
            centered: true,
          },

          1100: {
            slidesPerScroll: 1,
            slidesPerPage: 2,
            arrows: true,
            dots: true,
            centered: true,
          },
        }}
      >
        {images.map(({ node }, index) => {
          const newLocal = Modal(isModal, index, paths, node)
          return <div key={index}>{newLocal}</div>
        })}
      </Carousel>
    </>
  )
}
/* solution con functio ConvertLink2
 ConvertLink2(
            paths[index],
            <img
              className="img-fluid rounded shadow mx-auto my-2"
              
              alt={paths[index]}
              src={node.childImageSharp.fluid.src}
            />,
            index
          ) */
/* 
  Solution with fluid images ...GatsbyImageSharpFluid
  import Img from "gatsby-image"
    <div className="rounded shadow m-3 " style={{
      minWidth:'20rem',
      maxWidth:`60rem`
    }}>
      <Img
        fluid={node.childImageSharp.fluid}
        objectFit="cover"
        
        alt=""
      />
    </div>            
*/

BannerCarousel.propTypes = {
  images: PropTypes.array,
  paths: PropTypes.array,
  isModal: PropTypes.bool,
}

BannerCarousel.defaultProps = {
  images: [],
  paths: [],
  isModal: false,
}

function newFunction(index, paths, node) {
  let src_path = ""
  if (paths.length > 0) {
    src_path = paths[index]
    return (
      <>
        {/* <ConvertLink path={src_path} index={index}>
        <img
          className="img-fluid rounded shadow mx-auto my-2"
          alt={paths[index]}
          src={node.childImageSharp.fluid.src}
        />
      </ConvertLink> */}
        <ConvertLink path={src_path} index={index}>
          <img
            className="img-fluid rounded shadow mx-auto my-2"
            alt={paths[index]}
            src={node.childImageSharp.fluid.src}
          />
        </ConvertLink>
      </>
    )
  } else {
    src_path = node.publicURL
    return (
      <a href={src_path} target="_blank" rel="noopener noreferrer">
        <img
          className="img-fluid rounded shadow mx-auto my-2"
          alt={paths[index]}
          src={node.childImageSharp.fluid.src}
        />
      </a>
    )
  }
}

function Modal(isModal, index, paths, node) {
  if (isModal) {
    return (
      <ModalObject
        childLink={
          <div className="d-flex m-3">
            <img
              style={{
                width: `100%`,
                position: `relative`,
                overflow: `hidden`,
                height: `240px`,
              }}
              className="img-fluid rounded shadow p-4 "
              alt={paths[index]}
              src={node.childImageSharp.fluid.src}
            />
          </div>
        }
        modalTitle=""
        className="border border-dark rounded m-3 p-1"
      >
        <div>
          <a href={node.publicURL} target="_blank" rel="noopener noreferrer">
            <Image src={node.publicURL} fluid />
          </a>
        </div>

        {/* <Link to={`/${frontmatter.path}`} className="link-no-style">
              CONTINUA
            </Link> */}
      </ModalObject>
    )
  }

  return newFunction(index, paths, node)
}

export default BannerCarousel
